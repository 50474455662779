import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { axiosInstanceDocument, axiosInstanceEmail } from '../../../api/Axios';
import { getCustomerProgress } from '../ProgressBar/redux/slice';
import { downloadFile } from '../../../utils/downloadFile';
import { Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateCustomerProgress } from '../../containers/templateComponent/redux/slice';
import { mapping } from '../../../Helper/mapping';
import _ from 'lodash';
import { mandatoryDocs } from '../../../Helper/templateid';

const Review = (props) => {
  const {
    customerId,
    sectionSubmit,
    snackbarShowMessage,
    closeHandler,
    showReviewTemplate,
    customerpersona,
    setDisplay,
    actionId
  } = props;

  const [data, setData] = useState({});
  const [oldData, setOldData] = useState({});
  const [fileCount, setFileCount] = useState(-1);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState();
  const [comment, setComment] = useState('');
  const dispatch = useDispatch();
  const [isNcobReview,setisNcobReview] = useState(actionId === 19);
  const [isApproving,setIsApproving] = useState(false);
  const loadInitialData = () => {
    // if(showReviewTemplate.length === 1 && showReviewTemplate[0] === 0){
    //   axiosInstanceEmail
    //   .request({
    //     method: 'GET',
    //     url: `/getEmailDetails?customerId=${customerId}&actionId=${actionId}`
    //   })
    //   .then((response) => {
    //     if (response) {
    //       if (response?.data?.errorCode || response?.data?.errorMessage) {
            
    //       } else {
    //         const responseData = response.data.data.EmailInfo[0].documentList
    //         if(responseData.length > 0){
    //           let files = {};
    //           let count = 0;
    //           for (let i of responseData) {
    //             console.log(i)
    //             count += 1;
    //               files[i?.masterTemplate?.templateId] = {
    //                 ...i,
    //                 checked: false,
    //                 comment: i.comments,
    //                 open: false,
    //                 docName: i?.masterTemplate?.templateName
    //               };
    //           }
    //           setFileCount(count);
    //           setData(files);
    //           handleCommentClose();
    //         }
    //       }
    //     }
    //   });
    // }else{
    const InventoryReviewNeedApprovedDoc = [
      'Item list',
      'Order Guide',
    ];
    if(customerpersona && (actionId == 39 || actionId == 67)){
      axiosInstanceEmail
      .request({
        method: 'GET',
        url: `/getEmailAttachment?customerId=${customerId}&actionId=${actionId}`
      })
      .then((response) => {
        if (response?.data?.errorMessage) {
          setFileCount(0);
        }
        if (response?.data?.errorCode === null) {
          // console.log('response',response)
          
          const responseData = [{..._.get(response,'data.data.EmailInfo'),templateId:_.get(response,'data.data.EmailInfo.masterTemplate.templateId'),docName:_.get(response,'data.data.EmailInfo.masterTemplate.templateName')}]
          // console.log('showReviewTemplate',showReviewTemplate)
          // console.log('responseData',responseData)
          let files = {};
          let needcheckArr = []
          let count = 0;
          for (let i of responseData) {
            count += 1;
            if (showReviewTemplate?.includes(i?.templateId)) {
              files[i.templateId] = {
                ...i,
                checked: false,
                comment: i.comments,
                open: false,
                needBeforeApproved:(actionId === 65 && InventoryReviewNeedApprovedDoc.includes(i?.docName))?true:false
              };
            }
          }
          setFileCount(count);
          setData(files);
          handleCommentClose();
        }
      });
    }else{
    
      axiosInstanceDocument
      .request({
        method: 'GET',
        url: `/downloadDocuments/${customerId}`
      })
      .then((response) => {
        if (response?.data?.errorMessage) {
          setFileCount(0);
        }
        if (response?.data?.errorCode === null) {
          const responseData = response?.data?.data?.['Document Downloaded'];
          // console.log('showReviewTemplate',showReviewTemplate)
          // console.log('responseData',responseData)
          let files = {};
          let needchekcArr = []
          let count = 0;
          for (let i of responseData) {
            count += 1;
            if (showReviewTemplate?.includes(i?.templateId)) {
              needchekcArr.push(i)
             
            }
          }
          const groupByDocNameArr = sortClass(needchekcArr)
          groupByDocNameArr.map(res=>{
            files[res['name']] = {
              ...res['value'],
              checked: false,
              comment: res['value'].comments,
              open: false,
              needBeforeApproved:(actionId === 65 && InventoryReviewNeedApprovedDoc.includes(res['value']?.docName))?true:false
            }
          })
          // files[i.templateId] = {
          //   ...i,
          //   checked: false,
          //   comment: i.comments,
          //   open: false,
          //   needBeforeApproved:(actionId === 65 && InventoryReviewNeedApprovedDoc.includes(i?.docName))?true:false
          // };
          setFileCount(count);
          setData(files);
          setOldData(files);
          handleCommentClose();
        }
      });
        
    }
  };
  function sortClass(sortData){
    const groupBy = (array, f) => {
      let groups = {};
      array.forEach((o) => {
        let group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      // console.log(groups)
      // return groups
      return Object.keys(groups).map((group) => {
        return {
          name:group,
          value:groups[group].reduce((p,v) => p.versionId < v.versionId ? v : p)
        };
      });
    };
    if(sortData.length>0){
      const sorted = groupBy(sortData, (item) => {
        return item.templateId; // 返回需要分组的对象
      });
      return sorted;
    }else{
      return sortData
    }
  };
  useEffect(() => {
   
    if (customerId) {
      loadInitialData();
    }
  }, [customerId]);

  // useEffect(() => {
    // const mandatoryDoc = [
    //   'Item list',
    //   'Order Guide',
    //   'Inventory Snapshot',
    // ];
    // const docRequested = mandatoryDoc.filter((doc) => !Object.values(data)
    //   .some((value) => value.docName === doc));
    // if (Object.keys(data).length) {
    //   if(actionId === 65 && docRequested?.length > 0){
        
    //   }else{
        //COT-9083: if approved all files, not completed this step,this step can only be completed by clicking Mark this step as complete button 
        // let approved = true;
        // for (let i of Object.keys(data)) {
        //   if (data[i]?.isApproved !== 'Approved') {
        //     approved = false;
        //     break;
        //   }
        // }
          // if (approved && 'function' === typeof sectionSubmit) sectionSubmit(false, false);
  //     }
      
  //   }
  // }, [data]);
  function handleSubmit() {
    axiosInstanceDocument
      .request({
        method: 'GET',
        url: `/downloadDocuments/${customerId}`
      }).then((response) => {
        if (response?.data?.errorCode === null) {
          const responseData = response?.data?.data?.['Document Downloaded'];
          let files = formatDocuments(responseData);
          //check mandatory docs
          const needUpload = mandatoryDocs.filter(mDoc => !Object.values(files).some(diff => mDoc === diff.docName))
          if (needUpload?.length > 0) {
            snackbarShowMessage({
              message: `Mandatory Documents are missing, Please upload the ${needUpload.join(', ')} documents and proceed to approve!`,
              type: 'warning'
            });
            return;
          }
          //check if latest version get approved.
          const notApproveMandatory = Object.values(files).filter(file => mandatoryDocs.some(mDoc => file.docName === mDoc)).filter(item => item.isApproved !== 'Approved').map(f => {
            return f.docName;
          });
          if(notApproveMandatory?.length > 0){
            snackbarShowMessage({
              message: `${notApproveMandatory?.join(',')} exist(s) not approval latest version,please approve firstly!`,
              type: 'warning'
            });
            return;
          } 
          if ('function' === typeof sectionSubmit) sectionSubmit(false, false);
        } else {
          snackbarShowMessage({
            message: `${response?.data?.errorMessage}`,
            type: 'error'
          });
        }
      }).catch(err => {
        snackbarShowMessage({
          message: `Something went wrong. Please try again later.`,
          type: 'error'
        });
      });
  }
  const handleCommentOpen = (value) => {
    setActive(value);
    setComment(data[value]?.comment);
    setOpen(true);
  };
  function handleapprove_cust() {
    Object.keys(data).forEach((value) => {
      const checked = data[value]?.checked;
      if (!checked) {
        snackbarShowMessage({
          message: 'Please select checkbox ',
          type: 'error'
        });
        return;
      }
  
      snackbarShowMessage({
        message: 'Customer Approval triggered',
        type: 'success'
      });
  
      const Customer_Approval = mapping['CMU']?.find(
        (action) => action?.actionName === 'Customer Approval'
      );
      const reqData = _.map(_.filter(_.values(data), 'checked'), ({ templateId, comment, docStatusId }) => ({ templateId, customerId, isApproved: 'Approved', comment , docStatusId}));
      if(actionId === 67){
        dispatch(
          updateCustomerProgress({
            customerId,
            actionId: 67,
            sectionId: 8,
            sectionStatus: 'Completed',
            comments: ''
          })
        );
      }else{
        dispatch(
          updateCustomerProgress({
            customerId,
            actionId: Customer_Approval.actionId,
            sectionId: 4,
            sectionStatus: 'Completed',
            comments: ''
          })
        );
      }
      axiosInstanceDocument
      .request({
        method: 'POST',
        url: `/updateDocApproval`,
        data: reqData
      })
      setDisplay(0);
      dispatch(getCustomerProgress({ customerId }));
      setTimeout(() => {
        
        window.location.reload();
      }, 4000);
    });
  }
  function handleApprove() {
    setIsApproving(true);
    let mandatoryDoc
    if(actionId === 65){
      mandatoryDoc = [
        'Item list',
        'Order Guide',
        'Inventory Snapshot',
      ];
    }else{
      // mandatoryDoc = [
      //   'Item list',
      //   'MSA/LOI',
      //   'Customer Accounts',
      //   'Customer Vendor List'
      // ];
    }
    const InventoryReviewNeedApprovedDoc = [
      'Item list',
      'Order Guide',
    ];
    
    // const docRequested = mandatoryDoc.filter((doc) => !Object.values(data)
    //   .some((value) => value.docName === doc));
    
    const checkNeedBeforeApprovedFile = InventoryReviewNeedApprovedDoc.filter((doc) => Object.values(data)
    .some((value) => value.docName === doc && value.isApproved !== "Approved"));

    const reqData = Object.entries(data)
      .filter(([_, value]) => value.checked || value.isApproved === 'Approved')
      .map(([key, value]) => ({
        customerId,
        templateId: key,
        comments: value.comment,
        isApproved: value.checked || value.isApproved === 'Approved'
          ? 'Approved'
          : 'Rejected',
        docStatusId:value.docStatusId
      }));
    const checked = Object.values(data).some((value) => value.checked);
    //COT-9083:not need to check if uploaded customer account，item list ,customer vendor list ,MSA
    // if (docRequested?.length > 0) {
    //   snackbarShowMessage({
    //     message: `Mandatory Documents are missing, Please upload the ${docRequested.join(', ')} documents and proceed to approve`,
    //     type: 'warning'
    //   });
    //   return;
    // }
    if(actionId === 65 && checkNeedBeforeApprovedFile?.length > 0){
      snackbarShowMessage({
        message: `${checkNeedBeforeApprovedFile.join(', ')} ${checkNeedBeforeApprovedFile?.length>1?'are':'is'} not approved`,
        type: 'warning'
      });
      setIsApproving(false);
      return;
    }
    if (!checked) {
      snackbarShowMessage({
        message: 'To Proceed further, kindly Approve all the documents',
        type: 'warning'
      });
      setIsApproving(false);
      return;
    }
    axiosInstanceDocument
      .request({
        method: 'POST',
        url: `/updateDocApproval`,
        data: reqData
      })
      .then((response) => {
        if (response?.data?.errorMessage !== null) {
          snackbarShowMessage({
            message: response?.data?.errorMessage,
            type: 'error'
          });
          setIsApproving(false);
        } else {
          snackbarShowMessage({
            message: 'Updated Successfully',
            type: 'success'
          });
          loadInitialData();
          dispatch(getCustomerProgress({ customerId }));
          //bug fix: should not refresh page after approve doc in NCOB review screen
          // setTimeout(() => {
          //   window.location.reload();
          // }, [1000]);
          setIsApproving(false);
        }
      }).catch((error)=>{
        snackbarShowMessage({
          message: `Something went wrong. Please try again later`,
          type: 'error'
        });
        setIsApproving(false);
      });
  }
  function formatDocuments(docs){
    let files = {};
    let needchekcArr = []
    for (let i of docs) {
      if (showReviewTemplate?.includes(i?.templateId)) {
        needchekcArr.push(i)
      }
    }
    const groupByDocNameArr = sortClass(needchekcArr)
    groupByDocNameArr.map(res => {;
      files[res['name']] = {
        ...res['value'],
      }
    })
    return files;
  }
  function preApprove (){
    axiosInstanceDocument
      .request({
        method: 'GET',
        url: `/downloadDocuments/${customerId}`
      }).then((response) => {
        if (response?.data?.errorCode === null) {
          const responseData = response?.data?.data?.['Document Downloaded'];
          let files = formatDocuments(responseData);
          const diffData = Object.values(files).filter((doc) => !Object.values(oldData)
          .some((value) => value.templateId === doc.templateId && value.versionId === doc.versionId));
          if(diffData?.length > 0){
            snackbarShowMessage({
              message: `${diffData?.map(item=>item.docName).join(',')} exist(s) latest version,please refresh this action,then approve!`,
              type: 'warning'
            });
            return;
          }else{
             handleApprove();
          }
        }else{
          snackbarShowMessage({
            message: `${response?.data?.errorMessage}`,
            type: 'error'
          });
        }
      }).catch(err=>{
        snackbarShowMessage({
          message: `Something went wrong. Please try again later.`,
          type: 'error'
        });
      });
  }
  function handleCommentChange(event) {
    setComment(event.target.value);
  }

  function handleCommentClose() {
    setOpen(false);
    setComment('');
    setActive();
  }

  function handleSubmitComment() {
    if (data[active]?.comment !== comment) {
      let reqData = {
        customerId,
        templateId: active,
        comments: comment,
        isApproved: '',
        docStatusId:data[active]?.docStatusId
      };
      let prev = { ...data };
      prev = {
        ...prev,
        [active]: { ...prev[active], comment }
      };
      axiosInstanceDocument
        .request({
          method: 'POST',
          url: `/updateDocApproval`,
          data: [reqData]
        })
        .then((response) => {
          if (response?.data?.errorMessage !== null) {
            snackbarShowMessage({
              message: response?.data?.errorMessage,
              type: 'error'
            });
          } else {
            snackbarShowMessage({
              message: 'Commented Successfully',
              type: 'success'
            });
            loadInitialData();
          }
        });
      setData(prev);
    } else {
      snackbarShowMessage({ message: 'No Changes Found', type: 'warning' });
    }
  }

  const handleToggle = (value, action) => {
    let prev = { ...data };
    prev = {
      ...prev,
      [value]: { ...prev[value], [action]: !prev[value][action] }
    };
    setData(prev);
  };

  const downloadMainOperation= ({docName,templateId,fileName,docStatusId}) =>{
    // if(docName === "Inventory Readiness Tracker" || docName === "Inventory Snapshot"){
      axiosInstanceDocument
      .request({
          method: 'GET',
          url: `/downloadTrackerDocument/${templateId}/${customerId}/${docName=='MSA/LOI'?'MSALOI':docName}/${docStatusId}`,
          responseType: "blob"
      })
      .then((response) => {
          const contentType = response?.headers['content-type'];
          const url = window.URL.createObjectURL(
            new Blob([response?.data], { type: contentType })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            fileName
          );
          
          link.click();
      })
    // }else{
    //   downloadFile(templateId, customerId,fileName)
    // }
    
    
  }
  return (
    <div>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {fileCount === -1 && (
          <ListItem>
            {' '}
            <ListItemText primary="Loading Files..." />{' '}
          </ListItem>
        )}
        {fileCount === 0 && (
          <ListItem>
            {' '}
            <ListItemText primary="No files found" />{' '}
          </ListItem>
        )}

        {Object.keys(data).map((value) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <>
              <ListItem
                key={value}
                secondaryAction={
                  <>
                    <IconButton
                    data-testid="dwonloadicon"
                      edge="end"
                      aria-label="comments"
                      onClick={() => {
                        downloadMainOperation(data[value]);
                      }}
                    >
                      <DownloadIcon />
                    </IconButton>
                    &emsp;
                    {!customerpersona && (
                      <IconButton
                        edge="end"
                        aria-label="comments"
                        data-testid='ico'
                        disabled={data[value]?.isApproved === 'Approved'}
                        onClick={() => {
                          handleCommentOpen(value);
                        }}
                      >
                        <MarkUnreadChatAltOutlinedIcon />
                      </IconButton>
                    )}
                  </>
                }
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  dense
                  //   onClick={() => {
                  //     handleToggle(value, 'open');
                  //   }}
                >
                  <ListItemIcon>
                    <Checkbox
                    
                      onClick={() => {
                        handleToggle(value, 'checked');
                      }}
                      edge="start"
                      checked={data[value]?.checked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId,'data-testid':'checkbox' }}
                      disabled={data[value]?.isApproved === 'Approved' || data[value]?.needBeforeApproved}
                    />
                  </ListItemIcon>
                  
                  <ListItemText
                    id={labelId}
                    primary={
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={5}>
                          <b>{data[value]?.docName}</b>
                        </Grid>
                        <Tooltip
                          title={
                            data[value]?.isApproved === 'Approved'
                              ? `${data[value]?.docName} is Approved`
                              : ` ${data[value]?.docName} is Not Approved`
                          }
                          placement="left"
                        >
                          <Grid item xs>
                            <IconButton disabled>
                              <CheckCircleOutlinedIcon
                                sx={{
                                  color:
                                    data[value]?.isApproved === 'Approved'
                                      ? 'green'
                                      : ''
                                }}
                              />
                            </IconButton>
                            &nbsp; {data[value]?.fileName}
                          </Grid>
                        </Tooltip>
                      </Grid>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Collapse
                in={data[value].comment && data[value].open}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 10 }}>
                    <ListItemText primary={data[value].comments} />
                  </ListItemButton>
                </List>
              </Collapse>
            </>
          );
        })}
      </List>
      {Object.keys(data).length > 0 ? (
        <div className="button-wrapper">
       { !customerpersona && <Button
          sx={{ '& .MuiButton-root': { fontSize: 14 } }}
          id="secondary"
          className="buttons-1"
          onClick={closeHandler}
        >
          Cancel
        </Button>}
          <Button
            id="primary"
            className="buttons-2"
            onClick={customerpersona ? handleapprove_cust : preApprove}
            disabled={isApproving}
          >
            {isApproving ? 'Approving...' : 'Approve'}
            
          </Button>
          {isApproving && (
            <CircularProgress
              size={24}
              sx={{
                color: 'white',
                position: 'relative',
                left: '-55px'
              }}
            />
          )}
          {isNcobReview && <Button
            // sx={{ marginTop: 1 }}
            id="secondary"
            className="markbutton"
            data-testid="submit-btn"
            onClick={handleSubmit}
            style={{marginLeft: isApproving?'0px':'25px'}}
          >
            Mark this step as complete
          </Button>
          }
        </div>
      ) : (
        <div>{customerpersona ? 'No documents to review' :'No documents to review, Please upload a valid Documents'}
        </div>
      )}


      <Dialog
        open={open}
        onClose={handleCommentClose}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>
          <b>Comment</b>
        </DialogTitle>
        <DialogContent>
          <TextField
          
          inputProps={{ "data-testid": "commenttext" }}
            onChange={handleCommentChange}
            id="outlined-multiline-static"
            value={comment}
            margin="dense"
            fullWidth
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCommentClose}>Cancel</Button>
          <Button onClick={handleSubmitComment}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Review;
