
export function format(inputDate) {
  let date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
    return (
      ('0' + (date.getMonth() + 1)).slice(-2)+
      '/' +
      ('0' + date.getDate()).slice(-2) +
      '/' +
      date.getFullYear()
    );
  }
}
export function sortClass(sortData){
  const groupBy = (array, f) => {
    let groups = {};
    array.forEach((o) => {
      let group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    // return groups
    return Object.keys(groups).map((group) => {
      return {
        name:group,
        value:groups[group]
      };
    });
  };
  if(sortData.length>0){
    const sorted = groupBy(sortData, (item) => {
      return item.docName; 
    });
    return sorted;
  }else{
    return sortData
  }
};
